/* Fonts */
/* Family */
h1 { 
    font-family: 'Julius Sans One', sans-serif;
  }
  
  h2 { /* Contact, Skills, Education, About me, Work Experience */
    font-family: 'Archivo Narrow', sans-serif;
  }
  
  h3 { /* Accountant */
    font-family: 'Open Sans', sans-serif;
  }
  
  .jobPosition span, 
  .projectName span {
    font-family: 'Source Sans Pro', sans-serif;
  }
  
  .upperCase {
    text-transform: uppercase; 
  }
  
  .smallText, 
  .smallText span, 
  .smallText p, 
  .smallText a {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: justify;
    word-wrap: break-word;
    word-break: break-all; /* or word-break: break-word; */
  }
  
  /* End Family */
  
  /* Colors */
  h1 { 
    color: #111; 
  }
  
  .leftPanel, 
  .leftPanel a {
    color: #bebebe;
    text-decoration: none;
  }
  
  .leftPanel h2 {
    color: white;
  }
  
  /* End Colors */
  
  /* Sizes */
  h1 { 
    font-weight: 300; 
    font-size: 1.2cm;
    transform:scale(1,1.15); 
    margin-bottom: 0.2cm;
    margin-top: 0.2cm;
    text-transform: uppercase; 
  }
  
  h2 {
    margin-top: 0.1cm;
    margin-bottom: 0.1cm;
  }
  
  .leftPanel, 
  .leftPanel a {
    font-size: 0.38cm;
  }
  
  .projectName span, 
  .jobPosition span {
    font-size: 0.35cm;
  }
  
  .smallText, 
  .smallText span, 
  .smallText p, 
  .smallText a {
    font-size: 0.35cm;
  }
  
  .leftPanel .smallText, 
  .leftPanel .smallText, 
  .leftPanel .smallText span, 
  .leftPanel .smallText p, 
  .smallText a {
    font-size: 0.45cm;
  }
  
  .contactIcon {
    width: 0.5cm;
    text-align: center;
  }
  
  p {
    margin-top: 0.05cm;
    margin-bottom: 0.05cm;
  }
  /* End Sizes */
  
  .bolded {
    font-weight: bold;
  }
  
  .white {
    color: white;
  }
  /* End Fonts */
  
  /* Layout */
  /* body {
    background: rgb(204,204,204); 
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
  } */
  
  /* Printing */
  .page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
  }
  
  .sizeA4 {  
    width: 21cm;
    height: 29.7cm; 
  }
  
  @page {
     size: 21cm 29.7cm;
     padding: 0;
     margin: 0mm;
     border: none;
     border-collapse: collapse;
  }
  /* End Printing */
  
  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
  
  .leftPanel {
    width: 27%;
    background-color: #484444;
    padding: 0.7cm;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rightPanel {
    color: #111;
    width: 73%;
    padding: 0.7cm;
  }
  
  .leftPanel img {
    width: 4cm;
    height: 4cm;
    margin-bottom: 0.7cm;
    border-radius: 50%;
    border: 0.15cm solid white;
    object-fit: cover;
    object-position: 50% 50%;
  }
  
  .leftPanel .details {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .skill {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .bottomLineSeparator {
    border-bottom: 0.05cm solid white;
  }
  
  .yearsOfExperience {
    width: 1.6cm;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .alignleft {
    text-align: left !important;
    width: 1cm;
  }
  
  .alignright {
    text-align: right !important;
    width: 0.6cm;
    margin-right: 0.1cm
  }
  
  .workExperience>ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .workExperience>ul>li {
    position: relative;
    margin: 0;
    padding-bottom: 0.5cm;
    padding-left: 0.5cm;
  }
  
  .workExperience>ul>li:before {
    background-color: #b8abab;
    width: 0.05cm;
    content: '';
    position: absolute;
    top: 0.1cm;
    bottom: -0.2cm; /* change this after border removal */
    left: 0.05cm;
  }
  
  .workExperience>ul>li::after {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23484444' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    left: -0.09cm;
    top: 0;
    width: 0.35cm;
    height: 0.35cm;
  }
  
  .jobPosition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .item {
    padding-bottom: 0.7cm;
    padding-top: 0.7cm;
  }
  
  .item h2{
    margin-top: 0;
  }
  
  .lastParagrafNoMarginBottom {
    margin-bottom: 0;
  }
  
  .workExperience>ul>li ul {
    padding-left: 0.5cm;
    list-style-type: disc;
  }
  /*End Layout*/
  

/* styles/ResumeDisplay.module.css */
/* .pdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
  } */

 .pdfContainer {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
    overflow: auto;
  } 

  .page {
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }
  
  .page + .page {
    margin-top: 16px; 
  }
  
  
  .pdfPage {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .pdfContainer .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }