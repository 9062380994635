.resumeuploader {
    background-color: #fff; /* Black background */
    color: #000; /* White text color for better contrast */
    border: 2px solid #fff; /* Optional: White border for better visibility */
    padding: 20px; /* Padding inside the box */
    border-radius: 10px; /* Rounded corners */
    min-width: 50%; /* Minimum width of 50% */
    min-height: 50%; /* Minimum height of 50% */
    max-width: 80%; /* Optional: Maximum width of 80% */
    margin: 20px auto; /* Center the box horizontally with some margin */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Shadow for better aesthetics */
  }
  
  .resumeuploader h4 {
    margin-top: 0; /* Remove top margin for the heading */
  }
  
  .resumeuploader input[type="file"] {
    margin: 10px 0; /* Margin around the file input */
  }
  
  .resumeuploader button {
    background-color: #007bff; /* Blue button background */
    color: #fff; /* White text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .resumeuploader button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .resumeuploader pre {
    background-color: #333; /* Dark background for preformatted text */
    color: #fff; /* White text color */
    padding: 10px; /* Padding inside the pre block */
    border-radius: 5px; /* Rounded corners */
  }
  