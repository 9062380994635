.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-pills-custom {
  background-color: white;
}

.nav-pills-custom .nav-link {
  color: white;
}

.nav-pills-custom .nav-link.active {
  background-color: black;
  color: white;
}


.row
{
  margin-bottom: 5px;
}

.form-control
{
  margin: 10px;
}

.resumeuploader {
  background-color: #fff; /* White background */
  color: #000; /* Black text color for better contrast */
  border: 2px solid #ccc; /* Light gray border for better visibility */
  padding: 20px; /* Padding inside the box */
  border-radius: 10px; /* Rounded corners */
  min-width: 50%; /* Minimum width of 50% */
  min-height: 50%; /* Minimum height of 50% */
  max-width: 80%; /* Maximum width of 80% */
  margin: 20px auto; /* Center the box horizontally with some margin */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for better aesthetics */
  overflow: hidden; /* Prevent content overflow */
}

.resumeuploader h4 {
  margin-top: 0; /* Remove top margin for the heading */
}

.resumeuploader input[type="file"] {
  display: block; /* Ensure the file input takes up the full width */
  margin: 10px 0; /* Margin around the file input */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.resumeuploader button {
  background-color: #007bff; /* Blue button background */
  color: #fff; /* White text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding inside the button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.resumeuploader button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.resumeuploader pre {
  background-color: #333; /* Dark background for preformatted text */
  color: #fff; /* White text color */
  padding: 10px; /* Padding inside the pre block */
  border-radius: 5px; /* Rounded corners */
  overflow: auto; /* Allow scrolling for overflowing content */
}

.resumeuploader .file-info {
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowing text */
  display: block; /* Ensure it takes up the full width */
  margin: 10px 0; /* Margin around the file info */
}

.autoscrollable-wrapper {
  /*overflow: auto;*/
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;

  overflow: hidden; /* Hide the scrollbar */
  overflow-y: scroll; /* Add vertical scrolling */

  /* Hide scrollbar for WebKit browsers */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.or-container {
  display: flex; /* Establishes Flexbox layout */
  align-items: center; /* Vertically centers the items */
  justify-content: center; /* Horizontally centers the items */
  margin: 20px 0; /* Adds vertical spacing */
}

/* Line separator styling */
.line-separator {
  flex: 1; /* Allows the lines to expand and fill available space */
  height: 0.5px; /* Thickness of the line */
  background-color: white; /* Color of the line */
}

/* OR label styling */
.or-label {
  margin: 0 15px; /* Horizontal spacing between lines and label */
  color: white; /* Text color */
  font-weight: bold; /* Makes the text bold */
  white-space: nowrap; /* Prevents the text from wrapping */
}


.image-upload {
  width: 100%;
  height: 300px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  background: #f8f8f9;
  color: #666;
  overflow: hidden;
}


.item-wrapper form img {
  margin-bottom: 20px;
  width: auto;
  height: auto;
  max-height: 400px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
}


.image-upload img {
  height: 100%!important;
  width: auto!important;
  border-radius: 0px;
  margin: 0 auto;
}

.image-upload i {
  font-size: 6em;
  color: #ccc;
}


.image-upload input {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}


.item-wrapper input {
  height: 43px;
  line-height: 43px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.pdfContainer {
  width: 90%;
  height: '800px';
  margin: auto !important;
}

.LoaderText {
  color: 'white';
  margin-top: '20px' !important;
  font-size: '1.2rem' !important;
}

.resetPasswordButton {
  margin-left: 30px;
}

.rootContainer {
  padding-left: 50px;
  padding-right: 50px;
}

.jobDescriptionBox
{
  width: 100%;
  height: 250px;
}

@media (max-width: 600px) {
  .pdfContainer {
    width: 120%; 
    height: 400px;
    margin-left: -22px !important;
  }
  
  .page {
    transform: scale(0.25);
    transform-origin: top left;
  }

  .resetPasswordButton {
    margin: auto;
    margin-top: 10px;
  }
  .rootContainer {
    padding-left: 20px !important;
    padding-right: 50px !important;
  }

  .image-upload
  {
    width: 110%;
  }

  .jobDescriptionBox
  {
    width: 105%;
    height: 250px;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
