.container {
    padding: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .comparison {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .resumeVersion {
    width: 40%;
    border: 1px solid #ccc;
    padding: 10px;
    overflow: auto;
    height: 600px; /* Adjust as needed */
  }
  
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
  }
  
  .checkboxContainer div {
    margin-bottom: 10px;
  }

  .container {
    padding: 20px;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .comparison {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  